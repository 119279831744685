import moment from "moment";
import { ethers } from "ethers";

import { NETWORK } from "@constants/network";

import STAGING_CONFIG from "@constants/config/staging.json";
import PRODUCTION_CONFIG from "@constants/config/production.json";
import PREPRODUCTION_CONFIG from "@constants/config/preproduction.json";

function getConfig() {
  if (NETWORK.isTestnet) return STAGING_CONFIG;
  if (window.location.origin.includes("preprod.")) return PREPRODUCTION_CONFIG;
  return PRODUCTION_CONFIG;
}

const CONFIG_RAW = getConfig();

const CONFIG = {
  optimism: {
    contracts: {
      disbursementAddresses: CONFIG_RAW.optimism.contracts.disbursementAddresses.map(address =>
        ethers.utils.getAddress(address),
      ),
      simpleAccountFactory: ethers.utils.getAddress(CONFIG_RAW.optimism.contracts.simpleAccountFactory),
      simpleAccountFactoryOld: ethers.utils.getAddress(CONFIG_RAW.optimism.contracts.simpleAccountFactoryOld),
      beamnames: ethers.utils.getAddress(CONFIG_RAW.optimism.contracts.beamnames),
      beamnamesHelper: ethers.utils.getAddress(CONFIG_RAW.optimism.contracts.beamnamesHelper),
      vendingMachine: ethers.utils.getAddress(CONFIG_RAW.optimism.contracts.vendingMachine),
      vendingMachineHelper: ethers.utils.getAddress(CONFIG_RAW.optimism.contracts.vendingMachineHelper),
    },
    tokens: {
      eco: ethers.utils.getAddress(CONFIG_RAW.optimism.tokens.eco),
      ecox: ethers.utils.getAddress(CONFIG_RAW.optimism.tokens.ecox),
      usdc: ethers.utils.getAddress(CONFIG_RAW.optimism.tokens.usdc),
      usdv: ethers.utils.getAddress(CONFIG_RAW.optimism.tokens.usdv),
      usdce: ethers.utils.getAddress(CONFIG_RAW.optimism.tokens.usdce),
      usdt: ethers.utils.getAddress(CONFIG_RAW.optimism.tokens.usdt),
    },
  },
  base: {
    contracts: {
      disbursementAddresses: CONFIG_RAW.base.contracts.disbursementAddresses.map(address =>
        ethers.utils.getAddress(address),
      ),
    },
    tokens: {
      eco: ethers.constants.AddressZero,
      usdc: ethers.utils.getAddress(CONFIG_RAW.base.tokens.usdc),
      usdbc: ethers.utils.getAddress(CONFIG_RAW.base.tokens.usdbc),
      oak: ethers.utils.getAddress(CONFIG_RAW.base.tokens.oak),
    },
  },
  features: {
    saveAccess: {
      web3auth: {
        clientId: CONFIG_RAW.features.saveAccess.web3auth.clientId,
        verifier: CONFIG_RAW.features.saveAccess.web3auth.verifier,
        network: CONFIG_RAW.features.saveAccess.web3auth.network,
      },
      auth0: {
        domain: CONFIG_RAW.features.saveAccess.auth0.domain,
        clientId: CONFIG_RAW.features.saveAccess.auth0.clientId,
      },
      progressiveSecurity: {
        modalShowInterval: parseDuration(CONFIG_RAW.features.saveAccess.progressiveSecurity.modalShowInterval),
      },
    },
  },
  alchemy: {
    apiKey: CONFIG_RAW.alchemy.apiKey,
  },
  farcaster: {
    signingKey: CONFIG_RAW.farcaster.signingKey,
  },
  coinbase: {
    appId: CONFIG_RAW.coinbase.appId,
    deposit: {
      addresses: CONFIG_RAW.coinbase.deposits.addresses,
    },
  },
  walletconnect: {
    projectId: CONFIG_RAW.walletconnect.projectId,
  },
  s3: {
    endpoint: CONFIG_RAW.s3.endpoint,
  },
  cashramp: {
    publicKey: CONFIG_RAW.cashramp.publicKey,
    endpoint: CONFIG_RAW.cashramp.endpoint,
    graphqlUrl: CONFIG_RAW.cashramp.graphqlUrl,
    usdcEscrowAddress: CONFIG_RAW.cashramp.usdcEscrowAddress,
    usdcOfframpFee: CONFIG_RAW.cashramp.usdcOfframpFee,
    allowedCountries: CONFIG_RAW.cashramp.allowedCountries,
  },
  bendMigration: {
    origin: {
      domain: process.env.REACT_APP_MIGRATION_ORIGIN || CONFIG_RAW.bendMigration.origin.domain,
    },
    destination: {
      domain: process.env.REACT_APP_MIGRATION_DESTINATION || CONFIG_RAW.bendMigration.destination.domain,
    },
  },
};

function parseDuration(duration: string): number {
  return moment.duration(...duration.split(" ")).asMilliseconds();
}

export default CONFIG;

import { ethers } from "ethers";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Network, Token } from "@constants";
import { BalancesState, BalancesStateItem } from "@redux/slides/balances/balances.types";

const initialState: BalancesState = {
  networks: {
    optimism: {
      state: "loading",
      wallets: {},
    },
    base: {
      state: "loading",
      wallets: {},
    },
  },
};

export const executeTransfer = createAsyncThunk(
  "balances/executeTransfer",
  async ({
    tx,
  }: {
    address: string;
    network: Network;
    tx: Promise<unknown>;
    token: Token;
    amount: string;
    type?: "in" | "out";
  }) => {
    await tx;
  },
);

export const execTransferIn = (...params: Parameters<typeof executeTransfer>) =>
  executeTransfer({ ...params[0], type: "in" });

export const balancesSlice = createSlice({
  name: "balances",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<{ network: Network; balances: BalancesStateItem["wallets"] }>) => {
      const { network, balances } = action.payload;
      state.networks[network].state = "fetched";
      state.networks[network].wallets = balances;
    },
    incrementBalance: (
      state,
      action: PayloadAction<{ address: string; network: Network; token: Token; amount: string }>,
    ) => {
      const { address, network, token, amount } = action.payload;
      const { wallets } = state.networks[network];
      const balance = wallets[address][token];
      balance.amount = ethers.BigNumber.from(balance.amount).add(amount).toString();
    },
    updateAllowance: (
      state,
      action: PayloadAction<{ address: string; network: Network; token: Token; allowance: string }>,
    ) => {
      const { address, network, token, allowance } = action.payload;
      const { wallets } = state.networks[network];
      const balance = wallets[address][token];
      balance.allowance = allowance.toString();
    },
    reset: state => {
      state = initialState;
      return state;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(executeTransfer.pending, (state, action) => {
        const { address, amount, network, token, type = "out" } = action.meta.arg;
        const { wallets } = state.networks[network];
        const balance = wallets[address][token];
        const operation = type === "out" ? "sub" : "add";
        balance.amount = ethers.BigNumber.from(balance.amount)[operation](amount).toString();
        if (balance.allowance) {
          balance.allowance = ethers.BigNumber.from(balance.allowance)[operation](amount).toString();
        }
      })
      .addCase(executeTransfer.rejected, (state, action) => {
        const { address, amount, network, token, type = "out" } = action.meta.arg;
        const { wallets } = state.networks[network];
        const balance = wallets[address][token];
        const operation = type === "out" ? "add" : "sub";
        balance.amount = ethers.BigNumber.from(balance.amount)[operation](amount).toString();
        if (balance.allowance) {
          balance.allowance = ethers.BigNumber.from(balance.allowance)[operation](amount).toString();
        }
      });
  },
});

export const { set: setBalances, updateAllowance, incrementBalance, reset: resetBalances } = balancesSlice.actions;

export default balancesSlice.reducer;

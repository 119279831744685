import React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistentQueryClientContext } from "@contexts/PersistentQueryClientContext";
import { ApolloProvider } from "@apollo/client";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "@redux/store";
import { ConfigProvider } from "antd";
import { Auth0CustomContext } from "@contexts/Auth0CustomContext";
import { NETWORK } from "@constants/network";
import { APOLLO_CLIENTS } from "@constants/subgraphs";

const queryClient = new QueryClient();
const apolloClient = APOLLO_CLIENTS[NETWORK.network!];

function RootProviders({ children }: { children: React.ReactNode }) {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <PersistentQueryClientContext>
          <ApolloProvider client={apolloClient}>
            <ReduxProvider store={store}>
              <Auth0CustomContext>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#2F80ED",
                      colorIcon: "#FFFFFF",
                      colorLink: "#06153c",
                      fontFamily: "var(--font-stack-default)",
                    },
                  }}
                >
                  {children}
                </ConfigProvider>
              </Auth0CustomContext>
            </ReduxProvider>
          </ApolloProvider>
        </PersistentQueryClientContext>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export { RootProviders };

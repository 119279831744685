import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_REDIRECT_URI } from "@modules/web3auth/constants";

import { useAppDispatch } from "@redux";
import {
  setAfterRedirect,
  setIsAccountCreation,
  setResumeClaim,
  setResumeOnboarding,
  setResumeProgressiveSecurity,
  setResumeShopping,
} from "@redux/slides/web3auth.slide";

export const Auth0CustomContext: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleRedirect: Auth0ProviderOptions["onRedirectCallback"] = (state, user) => {
    if (state?.resumeClaim) {
      const query = localStorage.getItem("claim-query");
      if (query) {
        state.returnTo += query;
        localStorage.removeItem("claim-query");
      }
    }
    if (state?.returnTo) {
      navigate(state.returnTo);
    }
    if (user) {
      dispatch(setAfterRedirect(true));
      if (state?.resumeOnboarding) {
        dispatch(setResumeOnboarding(true));
      }
      if (state?.resumeClaim) {
        dispatch(setResumeClaim(true));
      }
      if (state?.isAccountCreation) {
        dispatch(setIsAccountCreation(true));
      }
      if (state?.resumeProgressiveSecurity) {
        dispatch(setResumeProgressiveSecurity(true));
      }
      if (state?.resumeShopping) {
        dispatch(setResumeShopping(true));
      }
    }
  };

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{ redirect_uri: AUTH0_REDIRECT_URI }}
      onRedirectCallback={handleRedirect}
    >
      {children}
    </Auth0Provider>
  );
};

import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { persistQueryClient } from "@helpers/persistQueryClient";

export const persistentQueryContext = React.createContext<QueryClient | undefined>(undefined);

export const PersistentQueryClientContext: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <QueryClientProvider client={persistQueryClient} context={persistentQueryContext}>
      {children}
    </QueryClientProvider>
  );
};

import * as Sentry from "@sentry/react";
import { ExtraErrorData, HttpClient } from "@sentry/integrations";
import { AxiosError } from "axios";

enum Environment {
  PRODUCTION = "production",
  PREPRODUCTION = "preproduction",
  STAGING = "staging",
  LOCAL = "localhost",
  OTHER = "other",
}

function getEnvironment() {
  switch (window.location.host) {
    case "beam.eco":
      return Environment.PRODUCTION;
    case "preprod.beam.eco":
      return Environment.PREPRODUCTION;
    case "staging.beam.eco":
      return Environment.STAGING;
    default:
      if (window.location.host.includes("localhost")) return Environment.LOCAL;
      return Environment.OTHER;
  }
}

function isAxiosError(error: unknown): error is AxiosError {
  return typeof error === "object" && error !== null && "isAxiosError" in error && Boolean(error.isAxiosError);
}

export function configure() {
  const env = getEnvironment();

  if (env === Environment.LOCAL) {
    console.warn("Sentry is disabled in local environment");
    return;
  }

  Sentry.init({
    dsn: "https://abca86e4577d4a8087dda06b776f9b81@o4505604587061248.ingest.sentry.io/4505605079760896",
    environment: env,
    sendDefaultPii: true,
    maxValueLength: 10_000,
    replaysSessionSampleRate: env === Environment.PRODUCTION ? 0.1 : 1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new ExtraErrorData({ depth: 10 }), new Sentry.Replay(), new HttpClient()],
    denyUrls: [/extensions\//i, /^chrome:\/\//i],
    beforeSend(event, hint) {
      if (hint && hint.originalException && isAxiosError(hint.originalException)) {
        if (hint.originalException.response && hint.originalException.response.data) {
          const contexts = { ...event.contexts };
          contexts.errorResponse = { data: hint.originalException.response.data };
          event.contexts = contexts;
        }
      }
      return event;
    },
  });
}

import { lazy } from "react";
import { createRoot } from "react-dom/client";
import { Route, Routes } from "react-router-dom";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import * as Sentry from "@modules/sentry";
import { RootProviders } from "@providers/RootProviders";

const Landing = lazy(() => import("./Landing"));
const App = lazy(() => import("./App"));

Sentry.configure();

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID!,
  });

  const root = createRoot(document.getElementById("root")!);
  root.render(
    <LDProvider>
      <RootProviders>
        <Routes>
          <Route path="/landing" element={<Landing />} />
          <Route path="*" element={<App />} />
        </Routes>
      </RootProviders>
    </LDProvider>,
  );
})();

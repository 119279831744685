import { FetchQueryOptions, QueryClient } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { persistQueryClient as _persistQueryClient, removeOldestQuery } from "@tanstack/react-query-persist-client";
import { compress, decompress } from "lz-string";

export const persistQueryClient = new QueryClient();

const localStoragePersistor = createSyncStoragePersister({
  key: "BEAM_OFFLINE_CACHE",
  storage: window.localStorage,
  retry: removeOldestQuery,
  serialize: data => compress(JSON.stringify(data)),
  deserialize: data => JSON.parse(decompress(data)),
});

const [, ready] = _persistQueryClient({
  queryClient: persistQueryClient,
  persister: localStoragePersistor,
});

export const fetchQuery: typeof persistQueryClient.fetchQuery = async (options: FetchQueryOptions) => {
  await ready;
  return persistQueryClient.fetchQuery(options);
};

import { useMemo } from "react";
import { ethers } from "ethers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { useAppSelector } from "@redux";

import { AuthType } from "@modules/web3auth/constants";
import { BasicUserInfo } from "@modules/web3auth/helpers";
import { IncreaseSecurityStep } from "@components/account/access/increase-security/IncreaseSecurityDrawer";

export interface Web3AuthState {
  signer?: string; // private key

  idToken?: string;
  authType?: AuthType;
  userInfo?: BasicUserInfo;
  isReconstructed: boolean;

  afterRedirect?: boolean;
  isAccountCreation?: boolean;
  resumeOnboarding?: boolean;
  resumeClaim?: boolean;
  resumeProgressiveSecurity?: boolean;
  resumeShopping?: boolean;
  // Progressive Security
  progressiveSecuritySheet: { open: boolean; initialStep?: IncreaseSecurityStep };
}

const initialState: Web3AuthState = {
  isReconstructed: false,
  progressiveSecuritySheet: { open: false },
};

export const web3authSlice = createSlice({
  name: "web3auth",
  initialState,
  reducers: {
    setSigner(state, action: PayloadAction<string | undefined>) {
      state.signer = action.payload && action.payload;
    },
    logout(state) {
      state = initialState;
      return state;
    },
    setUserData(
      state,
      action: PayloadAction<{
        idToken?: Web3AuthState["idToken"];
        userInfo: Web3AuthState["userInfo"];
      }>,
    ) {
      state.idToken = action.payload.idToken;
      state.userInfo = action.payload.userInfo;
    },
    setAuthType(state, action: PayloadAction<Web3AuthState["authType"]>) {
      state.authType = action.payload;
    },
    setReconstructed(state, action: PayloadAction<Web3AuthState["isReconstructed"]>) {
      state.isReconstructed = action.payload;
    },
    setAfterRedirect(state, action: PayloadAction<Web3AuthState["afterRedirect"]>) {
      state.afterRedirect = action.payload;
    },
    setIsAccountCreation(state, action: PayloadAction<Web3AuthState["isAccountCreation"]>) {
      state.isAccountCreation = action.payload;
    },
    setResumeOnboarding(state, action: PayloadAction<Web3AuthState["resumeOnboarding"]>) {
      state.resumeOnboarding = action.payload;
    },
    setResumeClaim(state, action: PayloadAction<Web3AuthState["resumeClaim"]>) {
      state.resumeClaim = action.payload;
    },
    setResumeProgressiveSecurity(state, action: PayloadAction<Web3AuthState["resumeProgressiveSecurity"]>) {
      state.resumeProgressiveSecurity = action.payload;
      if (action.payload) {
        state.progressiveSecuritySheet = { open: true };
      }
    },
    setResumeShopping(state, action: PayloadAction<Web3AuthState["resumeShopping"]>) {
      state.resumeShopping = action.payload;
    },
    setProgressiveSecurityOpen(state, action: PayloadAction<Web3AuthState["progressiveSecuritySheet"]>) {
      state.progressiveSecuritySheet = action.payload;
      state.resumeProgressiveSecurity = undefined;
    },
  },
});

export const {
  setAuthType,
  setAfterRedirect,
  setReconstructed,
  setIsAccountCreation,
  setResumeOnboarding,
  setResumeClaim,
  setResumeProgressiveSecurity,
  setResumeShopping,
  setUserData,
  setSigner: setWeb3AuthSigner,
  setProgressiveSecurityOpen,
  logout,
} = web3authSlice.actions;

export default web3authSlice.reducer;

export const useWeb3AuthState = () => useAppSelector(state => state.web3auth);
export const useWeb3AuthSigner = () => {
  const { signer } = useWeb3AuthState();
  return useMemo(() => signer && new ethers.Wallet(signer), [signer]);
};

import { Network as AlchemyNetwork } from "alchemy-sdk";

export const INFURA_ID = process.env.REACT_APP_INFURA_ID;

export const ENS_SUBGRAPH_URL = "https://api.thegraph.com/subgraphs/name/ensdomains/ens";

export type Network = "optimism" | "base";
export type AllNetwork = Network | "mainnet" | "op-sepolia" | "base-sepolia";

export interface INetwork {
  id: AllNetwork;
  short: string;
  network?: Network;
  name: string;
  color: string;
  rpcUrl: string;
  chainId: number;
  blockExplorerUrl: string;
  alchemyNetwork: AlchemyNetwork;
  subgraphUrl?: string;
  uniSubgraphUrl?: string;
  bundler: string;
  isTestnet?: boolean;
}

export const NETWORKS: Record<AllNetwork, INetwork> = {
  mainnet: {
    id: "mainnet",
    short: "m",
    name: "mainnet",
    color: "#ff8b9e",
    chainId: 1,
    blockExplorerUrl: "https://etherscan.io/",
    alchemyNetwork: AlchemyNetwork.ETH_MAINNET,
    bundler: "https://api.pimlico.io/v1/ethereum/rpc?apikey=c5c44203-a5cd-4d64-8ba2-55dcec5a220c",
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
  },
  optimism: {
    id: "optimism",
    short: "op",
    name: "Optimism",
    network: "optimism",
    color: "#f01a37",
    chainId: 10,
    alchemyNetwork: AlchemyNetwork.OPT_MAINNET,
    blockExplorerUrl: "https://optimistic.etherscan.io/",
    rpcUrl: `https://optimism-mainnet.infura.io/v3/${INFURA_ID}`,
    bundler: "https://api.pimlico.io/v1/optimism/rpc?apikey=c5c44203-a5cd-4d64-8ba2-55dcec5a220c",
    subgraphUrl:
      "https://gateway-arbitrum.network.thegraph.com/api/92496bb268b891d2fccf9c774b5bf50a/subgraphs/id/7ixohyRfaKMHzyiszBR2qBKcMBgjS4aRoP6ps6A3Rxf7",
    uniSubgraphUrl:
      "https://gateway-arbitrum.network.thegraph.com/api/92496bb268b891d2fccf9c774b5bf50a/subgraphs/id/EgnS9YE1avupkvCNj9fHnJxppfEmNNywYJtghqiu2pd9",
  },
  "op-sepolia": {
    id: "op-sepolia",
    short: "ops",
    name: "Optimism",
    network: "optimism",
    color: "#0975F6",
    chainId: 11155420,
    isTestnet: true,
    alchemyNetwork: AlchemyNetwork.OPT_SEPOLIA,
    blockExplorerUrl: "https://sepolia-optimism.etherscan.io/",
    rpcUrl: "https://opt-sepolia.g.alchemy.com/v2/VxDQsZXDgC3rXHCZGOpoKWTXGjPP0NQ8",
    bundler: "https://api.pimlico.io/v1/optimism-sepolia/rpc?apikey=c5c44203-a5cd-4d64-8ba2-55dcec5a220c",
    subgraphUrl: "https://api.studio.thegraph.com/query/80603/beam-op-sepolia/version/latest",
  },
  base: {
    id: "base",
    short: "b",
    name: "Base",
    network: "base",
    color: "#f01a37",
    chainId: 8453,
    alchemyNetwork: AlchemyNetwork.BASE_MAINNET,
    blockExplorerUrl: "https://basescan.org/",
    rpcUrl: `https://base-mainnet.g.alchemy.com/v2/k1F5fFw_2Zysxq1-86JYbO8h9vJnb2sf`,
    bundler: "https://api.pimlico.io/v1/base/rpc?apikey=c5c44203-a5cd-4d64-8ba2-55dcec5a220c",
    subgraphUrl:
      "https://gateway-arbitrum.network.thegraph.com/api/92496bb268b891d2fccf9c774b5bf50a/subgraphs/id/BHJxrcwWz8gw71pyCnKC9FHoGFMVvFfPazLhc5Jk9njo",
    uniSubgraphUrl:
      "https://gateway-arbitrum.network.thegraph.com/api/92496bb268b891d2fccf9c774b5bf50a/subgraphs/id/FUbEPQw1oMghy39fwWBFY5fE6MXPXZQtjncQy2cXdrNS",
  },
  "base-sepolia": {
    id: "base-sepolia",
    short: "bg",
    name: "Base",
    network: "base",
    color: "#f01a37",
    chainId: 84532,
    isTestnet: true,
    alchemyNetwork: AlchemyNetwork.BASE_SEPOLIA,
    blockExplorerUrl: "https://sepolia.basescan.org/",
    rpcUrl: `https://base-sepolia.g.alchemy.com/v2/a-t21zOYrtkHIOtI_DcC7Pr2XpDzagI2`,
    bundler: "https://api.pimlico.io/v1/base-sepolia/rpc?apikey=c5c44203-a5cd-4d64-8ba2-55dcec5a220c",
    subgraphUrl: "https://api.studio.thegraph.com/query/80603/beam-base-sepolia/version/latest",
  },
};

export function getSupportedNetworks() {
  // TODO iterate over supported networks comma separated ENV instead
  return [NETWORK, getNetwork("base")];
}

export function getNetworkById(chainId: number) {
  const network = Object.values(NETWORKS).find(network => network.chainId === chainId);
  if (!network) {
    throw new Error(`Network with chainId ${chainId} not found`);
  }
  return network;
}

export function getNetworkByShort(short: string) {
  return Object.values(NETWORKS).find(network => network.short === short);
}

export const NETWORK = NETWORKS[process.env.REACT_APP_NETWORK as keyof typeof NETWORKS];

if (NETWORK === undefined) {
  throw new Error("Network not found, please change the `REACT_APP_NETWORK` ENV or add the network to the list");
}

if (NETWORK.subgraphUrl === undefined) {
  throw new Error("Subgraph URL not set for current network");
}

export function getNetwork(network?: AllNetwork) {
  if (network === "base") {
    return NETWORK.id.includes("sepolia") ? NETWORKS["base-sepolia"] : NETWORKS.base;
  }
  return NETWORK;
}

export function getNetworkFromId(id: string) {
  return NETWORKS[id as Network];
}

export const NETWORK_LIST: Network[] = ["optimism", "base"];

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "@redux";

export interface UserState {
  beamname: {
    name?: string;
    tokenId?: number;
    exists: boolean;
  };
}

const initialState: UserState = {
  beamname: {
    exists: false,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setBeamname: (state, action: PayloadAction<UserState["beamname"]>) => {
      state.beamname = action.payload;
    },
    resetBeamname: state => {
      state.beamname = initialState.beamname;
    },
  },
});

export const { setBeamname, resetBeamname } = userSlice.actions;

export default userSlice.reducer;

export const useUserBeamname = () => useAppSelector(state => state.user.beamname);

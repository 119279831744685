import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { useAppSelector } from "@redux";
import { Network } from "@constants";

export interface UserOpMetadata {
  network: Network;
  hash: string;
}

export interface TransactionsState {
  userOpsBatches: UserOpMetadata[][];
}

const initialState: TransactionsState = {
  userOpsBatches: [],
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    addUserOps: (state, action: PayloadAction<UserOpMetadata[]>) => {
      state.userOpsBatches.push(action.payload);
    },
    removeUserOp: (state, action: PayloadAction<UserOpMetadata>) => {
      state.userOpsBatches = state.userOpsBatches
        .map(userOps => userOps.filter(userOp => userOp.hash !== action.payload.hash))
        .filter(batch => batch.length);
    },
  },
});

export const { addUserOps, removeUserOp } = transactionsSlice.actions;

export default transactionsSlice.reducer;

export const useTransactionsState = () => useAppSelector(state => state.transactions);

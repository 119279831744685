import { configureStore } from "@reduxjs/toolkit";

import userSlice from "@redux/slides/user.slide";
import configSlice from "@redux/slides/config.slide";
import balancesSlide from "@redux/slides/balances/balances.slide";
import merchantSlide from "@redux/slides/merchant.slide";
import web3authSlice from "@redux/slides/web3auth.slide";
import transactionsSlice from "@redux/slides/transactions.slide";

export const store = configureStore({
  reducer: {
    user: userSlice,
    config: configSlice,
    balances: balancesSlide,
    merchant: merchantSlide,
    web3auth: web3authSlice,
    transactions: transactionsSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
